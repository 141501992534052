<template>
  <div
    v-if="ready"
    :style="[layoutStyle, bgStyle]"
    :class="[layoutClass, bgClass]"
  >
    <!-- <loading-bar
      :visible="layout.barVisible"
      :eventBus="eventBus"
      v-on:loading-bar-end="playNext"
    ></loading-bar> -->
    <icon-element
      v-if="layout.socialIcon.visible"
      :icon="socialIcon"
      :layout="layout.socialIcon"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
    ></icon-element>
    <section-element
      v-if="layout.cta.visible"
      :text="content.cta"
      :icon="socialIcon"
      :layout="layout.cta"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
      :showText="layout.cta.showText"
      :showIcon="shouldShowCtaIcon"
    ></section-element>
    <social-avatar
      v-if="layout.avatar.visible"
      :url="content.avatar"
      :layout="layout.avatar"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
    ></social-avatar>
    <text-element
      v-if="layout.info.visible"
      :text="smInfo"
      :layout="layout.info"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
    ></text-element>
    <text-element
      v-if="layout.text.visible"
      :text="content.text"
      :layout="layout.text"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
      :hasHtml="isLinkedin"
    ></text-element>
    <image-element
      v-if="withImage"
      :url="content.image"
      :layout="layout.image"
      :skeleton="layout.skeleton"
    ></image-element>
    <rectangle-element
      v-if="layout.footer && layout.footer.visible"
      :layout="layout.footer"
      :skeleton="layout.skeleton"
      :hasImage="withImage"
    ></rectangle-element>
  </div>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "SocialSlideElement",
  mixins: [layoutMixin],
  components: {
    SectionElement: () => import("@/components/core/SectionElement"),
    SocialAvatar: () => import("./SocialAvatar"),
    ImageElement: () => import("@/components/core/ImageElement"),
    IconElement: () => import("@/components/core/IconElement"),
    TextElement: () => import("@/components/core/TextElement"),
    RectangleElement: () => import("@/components/core/RectangleElement"),
  },
  props: {
    content: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  watch: {
    //
  },
  computed: {
    ready: function() {
      if (this.content && this.layout) {
        return true;
      }

      return false;
    },
    withImage: function() {
      if (this.content && this.content.image) {
        return true;
      }
      return false;
    },
    isLinkedin: function() {
      if (this.content.socialType === 3) {
        return true;
      }
      return false;
    },
    socialIcon: function() {
      switch (this.content.socialType) {
        case 0:
          return ["fab", "facebook"];
        case 1:
          return ["fab", "instagram"];
        case 2:
          return ["fab", "twitter"];
        case 3:
          return ["fab", "linkedin"];
      }

      return ["fas", "hashtag"];
    },
    shouldShowCtaIcon: function() {
      return this.layout.cta.showIcon && this.content.cta !== null;
    },
    smInfo: function() {
      var info = "";
      if (this.content) {
        if (
          this.content.username &&
          this.layout &&
          this.layout.info &&
          this.layout.info.usernameVisible
        ) {
          info += this.content.username;
        }
        if (
          this.content.date &&
          this.layout &&
          this.layout.info &&
          this.layout.info.dateVisible
        ) {
          if (info.length > 0) {
            info += "\n";
          }
          info += moment(this.content.date).format("DD/MM/YYYY HH:mm");
        }
      }

      return info;
    },
  },
  methods: {
    //
  },
};
</script>
